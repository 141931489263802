import { createContext, useContext } from 'react';
import { EnsProcessDefinition } from './EnsProcess';
import { EnsPage } from '@/actions/progress';

export const ensProcessContext = createContext<EnsProcessDefinition<{}>>(null);

export const EnsProcessContextProvider = ensProcessContext.Provider;

export const useEnsProcessContext = () => {
  return useContext(ensProcessContext);
};

export const useIsInEnsProcessContext = () => {
  return !!useContext(ensProcessContext);
};

const currentEnsPageContext = createContext<EnsPage>(null);

export const CurrentEnsPageProvider = currentEnsPageContext.Provider;

export const useCurrentEnsPage = () => {
  return useContext(currentEnsPageContext);
};
