import { EnsPriceInfo } from '@/forms/shared/pricing/EnsPriceInfo';
import { useAppSelector } from '@/hooks';
import { type CustomProductPriceType } from '@/reducer/customProducts/customProducts.types';
import { useMemo } from 'react';

export const usePreriodGroupedTotalPrices = (includeUnlisted = false) => {
  const serverCustomProducts = useAppSelector((state) => state.customProducs.serverProductSelection) || null;

  const periodGroupedTotalPrices = useMemo(() => {
    if (!serverCustomProducts) return [];

    type GroupType = Partial<Record<CustomProductPriceType, number>>;
    const groups: GroupType = Object.values(serverCustomProducts)
      .filter((customProduct) => customProduct.amount > 0 && (customProduct.listable !== false || includeUnlisted))
      .reduce((groups, customProduct) => {
        for (const price of customProduct.prices || []) {
          if (price.disableInPriceSum) continue;
          groups[price.priceType] = (groups[price.priceType] || 0) + price.price * customProduct.amount;
        }
        return groups;
      }, {} as GroupType);

    return (Object.keys(groups) as CustomProductPriceType[]).map((priceType) =>
      EnsPriceInfo.fromCustomProductPrice({
        price: groups[priceType]!,
        priceType: priceType,
      })
    );
  }, [serverCustomProducts]);

  return periodGroupedTotalPrices;
};
