import { Group } from '@mantine/core';
import React from 'react';

export const PriceGroup = ({ children }) => {
  return (
    <Group
      position='apart'
      align='baseline'>
      {children}
    </Group>
  );
};
