import {
  CustomProduct,
  CustomProductImageDefinition,
  CustomProductNames,
} from '@/reducer/customProducts/customProducts.types';
import { Box, Modal, Image } from '@mantine/core';
import { useCounter, useFocusTrap } from '@mantine/hooks';
import React, { useState } from 'react';

const ParseImageInfo = (image: CustomProductImageDefinition) => {
  if (typeof image === 'string') {
    return { url: image, description: '' };
  } else {
    return image;
  }
};

const previewStyle: React.CSSProperties = {
  cursor: 'pointer',
  borderRadius: '0.3em',
  filter: 'drop-shadow(1px 3px 4px rgba(0, 0, 0, 0.1)',
};

export const CustomProductImageDisplay = <T extends CustomProductNames>(props: { productInfo: CustomProduct<T> }) => {
  const [modalOpen, setModelOpen] = useState(false);
  const [imgPos, handlers] = useCounter(0, { min: 0, max: props.productInfo?.imageUrls?.length - 1 });

  const imageList = props.productInfo?.imageUrls;
  const hasImages = imageList?.length > 0;

  return (
    <>
      <Box
        component='img'
        h='7em'
        maw='auto'
        src={hasImages ? ParseImageInfo(imageList[0]).url : undefined}
        onClick={!hasImages ? null : () => setModelOpen(true)}
        style={previewStyle}
      />

      <Modal
        overlayBlur={2}
        closeOnClickOutside={true}
        closeOnEscape={true}
        opened={modalOpen}
        onClose={() => setModelOpen(false)}
        withinPortal={false}
        title={props.productInfo.displayName}>
        <Image
          radius={'md'}
          src={ParseImageInfo(imageList[imgPos]).url}
        />
      </Modal>
    </>
  );
};
