import React, { useEffect } from 'react';
import { Group, Text, Loader } from '@mantine/core';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { HookedTextInput } from '@/forms/shared/HookedInputs';
import requestOffers from '@/actions/data/requestOffers';

export const EnsuranceListVoucherInput = () => {
  const dispatch = useAppDispatch();
  const voucherInfo = useAppSelector((state) => state.voucher.voucher);
  const voucherLoading = useAppSelector((state) => state.voucher.loading);

  const [lastVoucherState, setLastVoucherState] = React.useState(voucherInfo?.valid);

  useEffect(() => {
    if (lastVoucherState === voucherInfo?.valid || !voucherInfo?.valid) return;
    setLastVoucherState(voucherInfo?.valid);

    // Reload when voucher is now valid
    dispatch(requestOffers(true, true, true, { skipDefaultUserDataLoading: true }));
  }, [lastVoucherState, voucherInfo?.valid]);

  if (voucherInfo?.valid) return null;

  return (
    <Group
      position='apart'
      mb='sm'>
      <Text>Hast du einen Gutscheincode?</Text>
      <Group>
        {voucherLoading && <Loader />}
        <HookedTextInput
          disableWrap
          placeholder='Dein Gutscheincode'
          field='voucherCode'
        />
      </Group>
    </Group>
  );
};
