import { Action, AnyAction, PayloadAction, createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useCallback } from 'react';
import {
  CustomProducSelectState,
  CustomProduct,
  CustomProductNames,
  CustomProductSubmitProps,
  CustomServerProducSelectState,
  SetProducsOptions,
} from './customProducts.types';
import { addUserDataPostFields } from '@/actions/data/sharedPostField';
import { getUrl } from '@/actions/data';
import { reloadCustomProducts } from './reloadCustomProducts';

export const setProductState = createAction('customProducs/setProductState', function prepare<
  T extends CustomProductNames,
>(product: T, options: SetProducsOptions<T>) {
  return {
    payload: {
      product,
      options,
    },
  };
});

export const submitAdditionalProducts = createAsyncThunk(
  'customProducts/submit',
  async (props: CustomProductSubmitProps, thunkApi) => {
    const rootState = JSON.parse(JSON.stringify(thunkApi.getState())) as RootState;

    const formData = new FormData();
    addUserDataPostFields(rootState, rootState.userData, formData);

    formData.set('orderGroup', rootState.orderSubmit.orderGroup);

    const customProductsOrder = {};

    for (const [key, value] of Object.entries(props) as ObjectEntries<CustomProductSubmitProps>) {
      if (value) {
        customProductsOrder[key] = {
          options: value,
          product: rootState.customProducs.productSelection[key],
        };
      }
    }

    formData.set('customProductsOrder', JSON.stringify(customProductsOrder));

    const result = await fetch(getUrl('/api/ens/v1/customProducts/order'), {
      method: 'POST',
      body: formData,
    }).then((e) => e.json());

    return result;
  }
);

export const useCustomProduct = <T extends CustomProductNames>(name: T) => {
  const dispatch = useAppDispatch();
  const producsState: CustomProducSelectState<T> = useAppSelector(
    (state) => state.customProducs.productSelection[name]
  );

  const onChangeVariant = useCallback(
    (value: SetProducsOptions<T>['variant']) => {
      dispatch(setProductState(name, { variant: value }));
    },
    [dispatch, name]
  );

  const onChangeAmount = useCallback(
    (value: number | string) => {
      dispatch(setProductState(name, { amount: typeof value === 'string' ? parseInt(value) : value }));
    },
    [dispatch, name]
  );

  return [producsState, onChangeVariant, onChangeAmount] as const;
};

export type ServerCustomProductSelection<x extends CustomProductNames> = CustomProducSelectState<x> &
  CustomServerProducSelectState;

export const CustomProductsState = {
  productSelection: {} as Partial<{ [x in CustomProductNames]: CustomProducSelectState<x> | undefined }>,
  serverProductSelection: {} as Partial<{
    [x in CustomProductNames]: ServerCustomProductSelection<x> | undefined;
  }>,
  custom_products: {} as { [x in CustomProductNames]: CustomProduct<x> },
  custom_products_loading: false,
  custom_product_page: false as boolean,
  loading: false,
  submitResult: null as null | {
    success: boolean;
    messages: { type: 'success' | 'error' | 'warning'; message: string }[];
  },
};

export const CustomProductReducer = createReducer(CustomProductsState, (builder) => {
  builder.addCase(setProductState, (state, action) => {
    if (action.payload.options.variant === 'none') {
      delete state.productSelection[action.payload.product];
      return;
    }

    // @ts-ignore
    state.productSelection[action.payload.product] = {
      ...(state.productSelection[action.payload.product] || { amount: 1, variant: 'unknown' }),
      ...action.payload.options,
    };
  });

  builder.addCase('ENSURANCE_LOADED', (state, action: AnyAction) => {
    if (action.payload?.selectedCustomProductInfo) {
      state.serverProductSelection = action.payload.selectedCustomProductInfo;
    }
  });
  builder.addCase('ENS_FIELDS_LOADED', (state, action: AnyAction) => {
    if (action.mode !== 'default') return;
    state.custom_product_page = action.values.custom_product_page;
  });

  builder.addCase(submitAdditionalProducts.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(submitAdditionalProducts.fulfilled, (state, action) => {
    state.loading = false;
    state.submitResult = action.payload;
  });

  builder.addCase(reloadCustomProducts.pending, (state) => {
    state.custom_products_loading = true;
  });
  builder.addCase(reloadCustomProducts.fulfilled, (state, action) => {
    state.custom_products = action.payload;
    state.custom_products_loading = false;
  });

  builder.addMatcher(
    (action: AnyAction) => action.type === 'ENS_FIELDS_LOADED' && action.mode === 'default',
    (state, action: AnyAction) => {
      state.custom_products = action.values.custom_products;
    }
  );
});
