import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ColorPrimary } from './forms/shared/style/colors';
import { changeUserInput } from './actions/form';
import { EntryQuestionPosition } from './actions/types/DynamicEntryQuestionType';
import { getAutoloadEnsurances } from './features/AutoloadEns';
import { UserDataOrBike, UserDataOrBikeProp } from './reducer/userData';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const selectorIsLoading = (state: RootState, options: { includeEnsuranceListLoading?: boolean } | null = null) =>
  state.ensuranceList.submitting ||
  state.customProducs.loading ||
  (state.ensCompare.loading && getAutoloadEnsurances(state) === 'compare_standalone') ||
  (state.ensuranceList.loading && options?.includeEnsuranceListLoading) ||
  state.ensuranceList.loadingSelectedInsurance ||
  state.options.loading ||
  state.inspectionVoucher.submitting;

export const selectorIsInitialLoading = (state: RootState) => {
  const { presetIdState } = state.options;
  return (
    (presetIdState !== 'not_present' && presetIdState !== 'loading_finished') ||
    !state.ensfields.requiredFieldsLoaded ||
    state.ensuranceList.predefLoading
  );
};

export const selectPrimaryColor = (state) => state.options.settings.primaryColor || ColorPrimary;

export const useHasError = () => {
  const errors = useAppSelector((state) => state.validation.errors);
  return useCallback((fieldName) => errors.includes(fieldName), [errors]);
};

export const useOnInputFieldChange = (field, selector = null, bikeId = null, deps = []) => {
  const dispatch = useAppDispatch();
  return useCallback(
    (...changeProps) => dispatch(changeUserInput(field, (selector || ((o) => o))(...changeProps), bikeId)),
    [field, bikeId, ...deps]
  );
};

export type UserDataHook<T = any> = (
  field: any,
  bikeId?: string,
  onChangeSelector?: any,
  deps?: any[],
  useValueInCallbackDeps?: boolean
) => [T, (e: T) => void, boolean];

export const selectETargetValue = (e) => e.target.value;

export const useUserData = (<K extends string>(
  field: K,
  bikeId: string | undefined = undefined,
  onChangeSelector: any = (e) => e,
  deps = [],
  useValueInCallbackDeps = false
) => {
  const value = useAppSelector((state) =>
    !bikeId ? state.userData[field as any] : state.userData.bikes[bikeId][field]
  );
  const onChange = useOnInputFieldChange(field, onChangeSelector, bikeId, [
    ...deps,
    ...(useValueInCallbackDeps ? [value] : []),
  ]);

  const hasError = (
    useAppSelector((state) => (bikeId ? state.validation.bikeErrors[bikeId] : state.validation.errors)) || []
  ).includes(field);

  type valueType = K extends UserDataOrBikeProp ? UserDataOrBike[K] : any;

  return [value as valueType, onChange as (newValue: valueType) => void, hasError];
}) satisfies UserDataHook;

export const useInpectionUserData: UserDataHook = (
  field,
  _ = null,
  onChangeSelector: any = (e) => e,
  deps = [],
  useValueInCallbackDeps = false
) => {
  const dispatch = useDispatch();
  const hasError = useHasError();
  const value = useAppSelector((state) => state.inspectionVoucher.userData[field]);
  const changeEvent = useCallback(
    (...changeProps) => {
      dispatch({
        type: 'CHANGE_USER_INPUT_INSPECTIONVOUCHER',
        field,
        value: onChangeSelector(...changeProps),
      });
    },
    [field, ...deps, ...(useValueInCallbackDeps ? [value] : [])]
  );

  return [value, changeEvent, hasError(field)];
};

export const useOnAppReady = (callback, ...additionalDeps: any[]) => {
  const requiredFieldsLoaded = useAppSelector((state) => state.ensfields.requiredFieldsLoaded);
  const presetIdState = useAppSelector((state) => state.options.presetIdState);

  useEffect(() => {
    if (!requiredFieldsLoaded || presetIdState == '') return;
    callback();
  }, [callback, requiredFieldsLoaded, presetIdState, ...additionalDeps]);
};

export const useOnRequiredFieldsLoaded = (callback) => {
  const requiredFieldsLoaded = useAppSelector((state) => state.ensfields.requiredFieldsLoaded);

  useEffect(() => {
    if (!requiredFieldsLoaded) return;
    callback(true);
  }, [callback, requiredFieldsLoaded]);
};

export const useRequiredFieldsLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  const setter = useCallback(() => setLoaded(true), []);
  useOnRequiredFieldsLoaded(setter);
  return loaded;
};

export const useDynamicFields = (position: EntryQuestionPosition | null, filter = (_) => true) => {
  const customEntryOptions = useAppSelector((state) => state.ensfields.customquestions);

  return useMemo(
    () =>
      customEntryOptions
        .map((item) => {
          const newItem = { ...item };
          newItem.displayPositions = newItem.displayPositions || '';
          if (typeof newItem.displayPositions === 'string')
            newItem.displayPositions = newItem.displayPositions.split(',');
          return newItem;
        })
        .filter((o) => (!position || o.position === position) && filter(o)),
    [customEntryOptions, position]
  );
};
