import requestEnsurance from '@/actions/data/requestEnsurance';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useCurrentEnsPage } from '@/processes/processDefinition/DefaultEnsProcessContext';
import { useNextEvent } from '@/processes/processDefinition/ProcessEventHandler';
import { setProductState } from '@/reducer/customProducts';
import type { CustomProductNames } from '@/reducer/customProducts/customProducts.types';
import { Anchor, Group } from '@mantine/core';
import React, { useCallback } from 'react';

export const SkipCustomProductsButton = React.memo(() => {
  const currentPage = useCurrentEnsPage();
  const nextEvent = useNextEvent(currentPage);
  const dispatch = useAppDispatch();

  const customProducts = useAppSelector((state) => state.customProducs.serverProductSelection);

  const onSkipProducts = useCallback(async () => {
    const productsToRemove = (Object.keys(customProducts || {}) as CustomProductNames[]).filter(
      (key) => (customProducts[key]?.amount || 0) > 0
    );

    productsToRemove.forEach((key: CustomProductNames) => {
      if (key === 'cashbackVoucher') return;
      dispatch(setProductState(key, { amount: 0, variant: 'none' }));
    });

    await dispatch(requestEnsurance());

    nextEvent();
  }, [nextEvent, dispatch, customProducts]);

  return (
    <Group
      position='right'
      mb='-sm'>
      <Anchor
        ta='end'
        onClick={onSkipProducts}>
        Ohne Zusatzprodukte weiter
      </Anchor>
    </Group>
  );
});
