import { useAppSelector } from '@/hooks';
import { Spoiler, Textarea } from '@mantine/core';
import React from 'react';

export const DebugDisplayCustomProducts = () => {
  const voucherCode = useAppSelector((state) => state.userData.voucherCode);
  const offerVoucherInfo = useAppSelector((state) => ({
    voucher: state.checkout.offer.voucher,
    voucherTotalDiscountableAmount: state.checkout.offer.voucherTotalDiscountableAmount,
    voucherTotalDiscountableCustomProductPeriods: state.checkout.offer.voucherTotalDiscountableCustomProductPeriods,
    voucherTotalEuroCent: state.checkout.offer.voucherTotalEuroCent,
    voucherTotalEuroCentAfterCustomProductDiscount: state.checkout.offer.voucherTotalEuroCentAfterCustomProductDiscount,
  }));
  const voucherInfo = useAppSelector((state) => state.voucher.voucher);

  return (
    <Spoiler
      maxHeight={0}
      showLabel='Debug Info anzeigen'
      hideLabel='Verbergen'
      transitionDuration={200}>
      <Textarea
        label='Gutscheincode'
        readOnly
        value={JSON.stringify(voucherCode, null, 2)}></Textarea>

      <Textarea
        label='Offer Voucher Info'
        readOnly
        value={JSON.stringify(offerVoucherInfo, null, 2)}></Textarea>

      <Textarea
        label='Voucher Info'
        readOnly
        value={JSON.stringify(voucherInfo, null, 2)}></Textarea>
    </Spoiler>
  );
};
