import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { EnsPriceInfo } from './EnsPriceInfo';
import { useMemo } from 'react';
import { ActionIcon, Anchor, Box, Divider, Group, LoadingOverlay, Stack, Table, Text, Title } from '@mantine/core';
import Price2 from '@unserkunde/enscompare-components/src/components/feedback/Price2';
import numeral from 'numeral';
import { frequentDisplayName } from '../Common';
import { EnsuranceInfoButton } from '@/forms/ensuranceList/OfferPriceHints';
import { ServerCustomProductSelection, setProductState, useCustomProduct } from '@/reducer/customProducts';
import requestEnsurance from '@/actions/data/requestEnsurance';
import { CustomProductNames } from '@/reducer/customProducts/customProducts.types';
import { OverlayNames, openOverlay } from '@/processes/overlay/overlayReducer';
import { HiCursorClick } from 'react-icons/hi';
import { VoucherUsageSelection } from './VoucherUsageSelection';
import { EnsIcon } from '../AvailalbeEnsIcons';

import { PriceGroup } from './PriceGroup';
import { usePreriodGroupedTotalPrices } from '@/forms/checkout/inputs/CustomProducts/usePreriodGroupedTotalPrices';

export const CustomProductPriceRow = <T extends CustomProductNames>(props: {
  product: CustomProductNames;
  productInfo: ServerCustomProductSelection<T>;
}) => {
  const priceInfos = useMemo(
    () => props.productInfo?.prices?.map((p) => EnsPriceInfo.fromCustomProductPrice(p)),
    [props.productInfo]
  );

  const dispatch = useAppDispatch();
  const [customProductInfo, setVariant, setAmount] = useCustomProduct(props.product);

  const onAddRemove = useCallback(async () => {
    if (props.productInfo.isNotIncluded) {
      // Add Product
      if (!props.productInfo.itemsToAdd)
        await dispatch(setProductState(props.product, { variant: 'default', amount: 1 }));
      else
        await Promise.all(
          Object.values(props.productInfo.itemsToAdd).map((item) =>
            dispatch(setProductState(item.productName, { variant: item.variant as 'default', amount: 1 }))
          )
        );
    } else {
      // Remove Product
      if (!props.productInfo.itemsToRemove) {
        await dispatch(setProductState(props.product, { variant: 'none' }));
      } else {
        await Promise.all(
          props.productInfo.itemsToRemove.map(async (item) =>
            dispatch(setProductState(item.productName, { variant: 'none' }))
          )
        );
      }
    }
    await dispatch(requestEnsurance());
  }, [props.productInfo]);

  const maxAmount = useAppSelector((state) => state.customProducs.custom_products[props.product]?.maxAmount) || null;
  const canIncrease = maxAmount === null || (props.productInfo.amount || 0) < maxAmount;
  const canDecrease = props.productInfo.amount > 0;

  const increaseCounter = useCallback(async () => {
    if (!canIncrease) return;

    if (!props.productInfo.itemsToAdd) {
      await setAmount((props.productInfo.amount || 0) + 1);
      await dispatch(requestEnsurance());
    } else throw new Error('Not implemented incl custom products');
  }, [props.productInfo, canIncrease]);

  const decreaseCounter = useCallback(async () => {
    if (!canDecrease) return;

    const newAmount = Math.max((props.productInfo.amount || 0) - 1);

    if (newAmount > 0) await setAmount(newAmount);
    else {
      if (!props.productInfo.itemsToRemove) {
        await setVariant('none');
        await setAmount(0);
      } else throw new Error('Not implemented incl custom products');
    }
    await dispatch(requestEnsurance());
  }, [props.productInfo, canDecrease]);

  const detailsOverlay: OverlayNames | null = useMemo(() => {
    if (props.product === 'gpsTracker') return 'gpsTracker';
    if (props.product === 'premiumservice') return 'premiumservice';
    return null;
  }, [props.product]);

  const TextContainer = useMemo(() => {
    if (!detailsOverlay) return ({ children }) => children;

    return ({ children }) => (
      <Anchor
        color='blue'
        onClick={() => dispatch(openOverlay(detailsOverlay))}>
        <Box
          component={HiCursorClick}
          color='gray'
          mx={'xs'}
        />
        {children}
      </Anchor>
    );
  }, [detailsOverlay]);

  if (!priceInfos || priceInfos.length === 0) return null;

  return priceInfos.map((pi, i) => (
    <PriceGroup key={i}>
      <Stack
        spacing={0}
        align='flex-start'>
        {props.productInfo.isNotIncluded ? (
          <>
            {' '}
            <Price2.Price
              label={'Jetzt hinzufügen für ' + pi.displayFormat() + '€'}
              children={null}
              style={{ cursor: 'pointer' }}
              onClick={onAddRemove}
              reverseLabel={true}
            />
          </>
        ) : (
          <>
            <Price2.Price
              reverseLabel
              label={pi.getPeriodDisplay()}>
              {numeral(pi.amount).format('0.00')} €
            </Price2.Price>

            <Text
              onClick={onAddRemove}
              size={'xs'}
              color='gray'
              style={{ cursor: 'pointer' }}>
              Entfernen
            </Text>
          </>
        )}
      </Stack>
      <Group
        spacing={0}
        mr={'lg'}>
        <TextContainer>
          <Text
            style={{ display: 'inline-block', borderBottom: '1px dotted gray' }}
            td={props.productInfo.isNotIncluded ? 'line-through' : undefined}
            color={!detailsOverlay && props.productInfo.isNotIncluded && 'gray'}>
            {props.productInfo.displayName}
          </Text>
        </TextContainer>
        {props.productInfo.isNotIncluded && (
          <Text
            color='gray'
            fz='0.8em'>
            &nbsp;(nicht gewählt)
          </Text>
        )}

        <Group
          ml='sm'
          spacing='xs'
          style={{ whiteSpace: 'nowrap' }}>
          <ActionIcon
            size='sm'
            radius='xl'
            disabled={!canDecrease}
            onClick={decreaseCounter}
            variant='outline'>
            <EnsIcon
              color='gray'
              icon={'FaMinus'}
            />
          </ActionIcon>
          <Text>{props.productInfo.amount || '0'}</Text>
          <ActionIcon
            size='sm'
            radius='xl'
            disabled={!canIncrease}
            onClick={increaseCounter}
            variant='outline'>
            <EnsIcon
              color='gray'
              icon={'FaPlus'}
            />
          </ActionIcon>
        </Group>
      </Group>
    </PriceGroup>
  ));
};

export const EnsProductRow = () => {
  const offer = useAppSelector((state) => state.checkout.offer);

  return (
    <PriceGroup>
      <Price2.Price
        reverseLabel
        label={
          <React.Fragment>
            {frequentDisplayName(offer?.period)}
            <EnsuranceInfoButton offer={offer} />
          </React.Fragment>
        }>
        {numeral(offer.offer).format('0.00')} €
      </Price2.Price>

      <Text mr={'lg'}>Versicherung</Text>
    </PriceGroup>
  );
};

const CashbackDisplay = () => {
  const offer = useAppSelector((state) => state.checkout.offer);

  if (!offer.voucherTotalEuroCent) return null;

  return (
    <PriceGroup>
      <Price2.Price
        reverseLabel
        label={frequentDisplayName('once')}>
        {numeral(-offer.voucherTotalEuroCent).format('0.00')} €
      </Price2.Price>

      <Text mr={'lg'}>Gutschein</Text>
    </PriceGroup>
  );
};

export const SumDisplay = () => {
  const offer = useAppSelector((state) => state.checkout.offer);
  const customProductPrices = useAppSelector((state) => state.customProducs.serverProductSelection) || {};

  const offerInfoFirstYear = useMemo(
    () =>
      !offer.period
        ? EnsPriceInfo.empty()
        : new EnsPriceInfo(
            offer.prices_firstYear?.brutto?.reduce((a, b) => a + b, 0) || 0,
            EnsPriceInfo.periodFromOffer(offer),
            'unknown'
          ),
    [offer]
  );

  const offerInfo = useMemo(
    () =>
      !offer.period
        ? EnsPriceInfo.empty()
        : new EnsPriceInfo(
            offer.prices?.brutto?.reduce((a, b) => a + b, 0) || 0,
            EnsPriceInfo.periodFromOffer(offer),
            'unknown'
          ),
    [offer]
  );

  const productInfos = useMemo(() => {
    return [
      ...Object.keys(customProductPrices).map((k) =>
        customProductPrices[k].isNotIncluded
          ? null
          : customProductPrices[k].prices?.map((p) => EnsPriceInfo.fromCustomProductPrice(p))
      ),
    ]
      .flat()
      .filter((o) => o);
  }, [customProductPrices]);

  const sumFirstYear = useMemo(() => {
    return [...productInfos, offerInfoFirstYear].reduce(
      (acc: EnsPriceInfo, pi: EnsPriceInfo) => (acc ? acc.add(pi) : null),
      new EnsPriceInfo(0, 'once', 'unknown')
    );
  }, [offerInfoFirstYear, productInfos]);

  const sumAfterward = useMemo(() => {
    return [...productInfos, offerInfo].reduce(
      (acc: EnsPriceInfo, pi: EnsPriceInfo) => (acc ? acc.add(pi) : null),
      new EnsPriceInfo(0, 'once', 'unknown')
    );
  }, [productInfos, offerInfo]);

  if (sumFirstYear === null || sumAfterward === null) return null;

  const hasDifferentFirstYear =
    offer.prices_firstYear && sumFirstYear && sumFirstYear.amount > 0 && sumFirstYear.amount !== sumAfterward.amount;

  return (
    <Stack>
      <Divider />

      {/* {hasDifferentFirstYear && (
        <PriceGroup>
          <Price2.Price
            reverseLabel
            label={sumFirstYear.getPeriodDisplay()}>
            {numeral(sumFirstYear.amount).format('0.00')} €
          </Price2.Price>

          <Text mr={'lg'}>Summe 1. Jahr</Text>
        </PriceGroup>
      )}
 */}
      <PriceGroup>
        <Price2.Price
          reverseLabel
          label={sumAfterward.getPeriodDisplay()}>
          {numeral(sumAfterward.amount).format('0.00')} €
        </Price2.Price>

        <Text mr={'lg'}>{hasDifferentFirstYear ? 'Gesamt' : 'Gesamt'}</Text>
      </PriceGroup>
    </Stack>
  );
};

const CustomProductsGrouped = () => {
  const periodGroupedTotalPrices = usePreriodGroupedTotalPrices();

  const voucherAmount = useAppSelector((state) => state.checkout.offer?.voucherTotalEuroCent);

  return (
    <>
      <Title order={6}>Zusatzprodukte</Title>
      {periodGroupedTotalPrices.map((pi, i) => (
        <PriceGroup key={pi.period}>
          <Price2.Price
            reverseLabel
            label={pi.getPeriodDisplay()}>
            {numeral(pi.amount).format('0.00')} €
          </Price2.Price>
          <Text></Text>
        </PriceGroup>
      ))}
      {voucherAmount && (
        <PriceGroup>
          <Price2.Price
            size='xs'
            label={'Abz. Gutschrift'}>
            {EnsPriceInfo.once(-voucherAmount).displayFormat()}&nbsp;€
          </Price2.Price>
          <Text></Text>
        </PriceGroup>
      )}
    </>
  );
};

export const TabledPriceDisplay = (props: { hideCustomProducts?: boolean; customProductsGrouped?: boolean }) => {
  const serverCustomProducts = useAppSelector((state) => state.customProducs.serverProductSelection) || {};

  const loading = useAppSelector((state) => state.checkout.loading || state.voucher.loading);

  return (
    <Stack style={{ position: 'relative' }}>
      {props.hideCustomProducts && <Divider />}
      <EnsProductRow />
      {(!props.hideCustomProducts || props.customProductsGrouped) && <Divider />}

      {!props.hideCustomProducts &&
        Object.keys(serverCustomProducts).map((sk) => (
          <CustomProductPriceRow
            key={sk}
            product={sk as CustomProductNames}
            productInfo={serverCustomProducts[sk]}
          />
        ))}
      {props.customProductsGrouped && <CustomProductsGrouped />}

      {/* <CashbackDisplay /> */}
      {/* <VoucherUsageSelection /> */}

      {!props.hideCustomProducts && <SumDisplay />}

      <LoadingOverlay visible={loading} />
    </Stack>
  );
};
