import { updateVoucher } from '../data/updateVoucher';

let updateDebounce = null;
const debounceTime = 2000;

export const filterVoucher = (fieldName, fieldValue) => {
  return (dispatch, getState: RootStateGetter) => {
    if (fieldName !== 'voucherCode') {
      return;
    }

    if (updateDebounce) {
      clearTimeout(updateDebounce);
    }

    updateDebounce = setTimeout(async () => {
      const state = getState();

      if (fieldValue === state.voucher.loadingCode || state.voucher.voucher?.code === fieldValue) return;
      await dispatch(updateVoucher());
    }, debounceTime);
  };
};
