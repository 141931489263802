import { getUrl } from '../shared/getUrl';
import { addCustomFilter, addUserDataPostFields } from './sharedPostField';

let abortController = new AbortController();
let { signal } = abortController;

const resetAbortController = () => {
  abortController.abort();
  abortController = new AbortController();
  signal = abortController.signal;
};

const requestEnsurance =
  (userDataOverride = {}) =>
  async (dispatch, getState) => {
    const state = { ...getState() };
    const request = {
      ...JSON.parse(JSON.stringify(state.userData)),
      ...userDataOverride,
    };
    const { name } = state.checkout;

    if (!name) {
      return;
    }
    if (!request.paymentPeriod) {
      console.log('Angebot wird nicht abgerufen. Keine Zahlungsperiode ausgewählt');
      return;
    }

    const formData = new FormData();

    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);
    formData.append('ensName', name);

    await dispatch({ type: 'ENSURANCE_LOADING' });

    resetAbortController();

    const result = await fetch(getUrl('/wp-json/ens/v1/ensOffer/getOffer'), {
      body: formData,
      method: 'post',
      signal,
    })
      .then((o) => o.json())
      .catch((e) => {
        // Request aborted
        if (e?.code === 20) return null;

        console.log('error', e);
        return [];
      });

    if (!result) return;

    await dispatch({ type: 'ENSURANCE_LOADED', offer: result.offer, payload: result });

    if (result.stripeProductInfo)
      await dispatch({ type: 'STRIPE_PRODUCT_INFO_LOADED', payload: result.stripeProductInfo });
  };

export default requestEnsurance;
