import { ScrollIntoView } from '@/forms/shared/ScrollInfoView';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  Box,
  Text,
  Container,
  Group,
  LoadingOverlay,
  Space,
  Title,
  Loader,
  Center,
  Card,
  Select,
  Stack,
  Spoiler,
} from '@mantine/core';
import React, { useCallback, useEffect, useMemo } from 'react';

import numeral from 'numeral';
import { type CustomProductNames, type CustomProductPrice } from '@/reducer/customProducts/customProducts.types';
import requestEnsurance from '@/actions/data/requestEnsurance';
import { useCustomProduct } from '@/reducer/customProducts';
import { EnsPriceInfo } from '@/forms/shared/pricing/EnsPriceInfo';
import { Checklist, Price2 } from '@unserkunde/enscompare-components';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { updateVoucher } from '@/actions/data/updateVoucher';
import { DebugDisplayCustomProducts } from './DebugDisplayCustomProducts';

import { usePremiumserviceChecklist } from '@/forms/InspectionVoucher/InspectionVoucherOrderComponent';
import { GpsTrackerHeadpoints } from '@/forms/success/GpsSelectionCard';
import { usePreriodGroupedTotalPrices } from './usePreriodGroupedTotalPrices';
import { CustomProductImageDisplay } from './CustomProductImageDisplay';
import { useCashbackIsSelected } from './productInfos/useCashbackIsSelected';
import { SkipCustomProductsButton } from './SkipCustomProductsButton';

export const CustomProductRow = (props: { productKey: CustomProductNames }) => {
  const customProduct = useAppSelector((state) => state.customProducs.serverProductSelection[props.productKey]);
  const productBaseInfo = useAppSelector((state) => state.customProducs.custom_products[props.productKey]);

  const [customProductInfo, setVariant, setAmount] = useCustomProduct(props.productKey);

  const dispatch = useAppDispatch();

  const setAmountCallback = useCallback(
    async (newValue) => {
      if (!customProduct.itemsToAdd) {
        const newValueNum = parseInt(newValue);
        if (newValueNum) await setAmount(newValueNum);
        else {
          await setAmount(0);
          await setVariant('none');
        }

        await dispatch(requestEnsurance());
      } else throw new Error('Not implemented incl custom products');
    },
    [customProduct, setAmount, setVariant]
  );

  const priceInfos = useMemo(
    () =>
      customProduct?.prices
        ?.filter((p: CustomProductPrice) => !p?.disablePriceDisplay)
        .map((p) => EnsPriceInfo.fromCustomProductPrice(p)),
    [customProduct]
  );

  const maxAmount = productBaseInfo?.maxAmount;
  const amounts = useMemo(() => {
    return Array.from({ length: (maxAmount || 5) + 1 }, (_, i) => i + 1).map((i) => (i - 1).toString());
  }, [maxAmount]);

  const psChecklist = usePremiumserviceChecklist(false);

  const DetailsElement = useMemo(() => {
    if (props.productKey === 'premiumservice' || props.productKey === 'gpsTracker') {
      return (
        <Checklist
          isOpen={true}
          /* @ts-ignore */
          items={props.productKey === 'premiumservice' ? psChecklist.items : GpsTrackerHeadpoints.items}
          preview={0}
          labelClosed={null}
          labelOpen={null}
        />
      );
    } else if (props.productKey === 'sta_product1') {
      return (
        <Checklist
          isOpen={true}
          items={[
            {
              uuid: 1,
              type: 'positive',
              children: <b>Erstinspektionsgutschein im Wert von 70,-€</b>,
            },
            ...[
              'Überprüfung der Kürzungen und Ablehnungen',
              'Schadenmanagement & Direktabrechnung mit Werkstatt',
              'Werkstattsuche im Schadenfall',
              'Papierlose Bearbeitung inkl. digitalem Versicherungsordner',
              'Unterstützung bei der Ersatzteilversorgung',
              'Rückmeldung innerhalb von 24 Stunden werktags garantiert',
            ].map((text, i) => ({
              uuid: i + 2,
              type: 'positive',
              children: text,
            })),
          ]}
          preview={0}
          labelClosed={null}
          labelOpen={null}
        />
      );
    } else if (props.productKey === 'sta_product2') {
      return (
        <Checklist
          isOpen={true}
          items={[
            {
              uuid: 1,
              type: 'positive',
              children: <b>Erstinspektionsgutschein im Wert von 70,-€</b>,
            },
          ]}
          preview={0}
          labelClosed={null}
          labelOpen={null}
        />
      );
    } else if (props.productKey === 'cashbackVoucher') {
      return (
        <Text>
          Das Cashback wird zwei Monate nach Versicherungsbeginn, frühstens aber zum darauffolgenden 15. ausgezahlt,
          sofern der Erstbeitrag bezahlt und der Vertrag ungekündigt besteht und nicht widerrufen wurde.
        </Text>
      );
    }
    return null;
  }, [props.productKey, psChecklist]);

  return (
    <Card
      shadow='sm'
      p='lg'
      radius='md'
      style={{ overflow: 'visible' }}
      withBorder>
      <Group
        position='apart'
        align='flex-start'
        noWrap>
        <Group
          align='flex-start'
          noWrap>
          <CustomProductImageDisplay productInfo={productBaseInfo} />

          <Box>
            <Title order={6}>{customProduct.displayName}</Title>
            {DetailsElement && (
              <Spoiler
                initialState={true}
                maxHeight={0}
                showLabel='Details ansehen'
                hideLabel='Details verbergen'>
                {DetailsElement}
              </Spoiler>
            )}
          </Box>
        </Group>

        <Stack
          spacing='xs'
          w='min-content'>
          <Select
            value={customProductInfo?.amount?.toString() || '0'}
            onChange={setAmountCallback}
            data={amounts}
            miw='5em'
          />

          <>
            {priceInfos?.map((priceInfo) => (
              <Price2>
                <Price2.Price
                  label={
                    <>
                      {priceInfo.getPeriodDisplay()}
                      {customProduct.amount >= 2 ? <>&nbsp;Je</> : ''} {numeral(priceInfo.amount).format('0.00')} €
                    </>
                  }
                  size={'lg'}>
                  &nbsp;
                </Price2.Price>
              </Price2>
            ))}
          </>
        </Stack>
      </Group>
      {GetIsDevMode() && (
        <Spoiler
          maxHeight={0}
          hideLabel={'Verbergen'}
          showLabel={'Debug Info'}>
          Test
        </Spoiler>
      )}
    </Card>
  );
};

export const CustomProductsSelection = () => {
  const serverCustomProducts = useAppSelector((state) => state.customProducs.serverProductSelection) || {};
  const loading = useAppSelector((state) => state.checkout.loading);

  const sortedProductKeys = useMemo(() => {
    return Object.keys(serverCustomProducts)
      .filter((key: CustomProductNames) => serverCustomProducts[key].listable !== false)
      .sort((keyA, keyB) => {
        const valA: number | string = serverCustomProducts[keyA].sortValue || 0;
        const valB: number | string = serverCustomProducts[keyB].sortValue || 0;

        if (valA < valB) return -1;
        if (valA > valB) return 1;
        return 0;
      });
  }, [serverCustomProducts]);

  return (
    <>
      {sortedProductKeys.map((productKey: CustomProductNames, i: number) => (
        <Box
          key={productKey}
          mt={'lg'}>
          <CustomProductRow productKey={productKey} />
        </Box>
      ))}
      <LoadingOverlay visible={loading} />
    </>
  );
};

export const TotalRowVersand = () => {
  const versandItem = useAppSelector((state) => state.customProducs.serverProductSelection['shipping'] || null);

  const ensPrices = useMemo(
    () => (versandItem?.prices || []).map((prc) => EnsPriceInfo.fromCustomProductPrice(prc)),
    [versandItem]
  );

  if (!versandItem) return null;

  return (
    <>
      {ensPrices.map((priceInfo) => (
        <Price2.Price
          mt='xs'
          size='sm'
          label={versandItem.displayName}>
          {priceInfo.displayFormat()} €
        </Price2.Price>
      ))}
    </>
  );
};

export const TotalRow = () => {
  const periodGroupedTotalPrices = usePreriodGroupedTotalPrices();
  const periodGroupedTotalPricesUnlisted = usePreriodGroupedTotalPrices(true);

  const hasCashback = useCashbackIsSelected();
  const voucherAmount = useAppSelector((state) => state.checkout.offer?.voucherTotalEuroCent);

  const deductableVoucherAmount = hasCashback ? 0 : voucherAmount;

  const firstInvoiceAmount = useMemo(() => {
    return EnsPriceInfo.once(
      periodGroupedTotalPricesUnlisted.reduce((sum, priceInfo) => sum + priceInfo.amount, 0) -
        (deductableVoucherAmount || 0)
    );
  }, [periodGroupedTotalPricesUnlisted, deductableVoucherAmount]);

  return (
    <>
      <Group
        position='apart'
        mt='lg'>
        <Text></Text>
        <Box>
          {hasCashback ? (
            <>
              <Title
                order={6}
                ta='end'
                mb='sm'>
                Du erhältst eine Auszahlung in Höhe von {numeral(voucherAmount).format('0.00')} €
              </Title>
            </>
          ) : (
            <Title
              order={6}
              ta='end'
              mb='sm'>
              Dein Kundenkonto erhält eine Gutschrift in Höhe von {numeral(voucherAmount).format('0.00')} €
            </Title>
          )}
          {periodGroupedTotalPrices.map((priceInfo) => (
            <Price2.Price
              key={priceInfo.period}
              size='md'
              label={<>Ihre {priceInfo.getPeriodAdverb()} kosten</>}>
              {priceInfo.displayFormat()} €
            </Price2.Price>
          ))}
          <TotalRowVersand />
          <Space h={'sm'} />

          {!hasCashback && (
            <Text mb='sm'>
              Nach Verrechnung der Gutschrift stellen wir dir erstmalig folgenden Betrag in Rechnung:{' '}
              <Box
                component='span'
                c='primary'
                fw='bold'>
                {firstInvoiceAmount.isNegative() ? '0,00' : firstInvoiceAmount.displayFormat()}&nbsp;€
              </Box>
            </Text>
          )}
        </Box>
      </Group>
    </>
  );
};

export const useCustomProductsInitialLoaded = () => {
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateVoucher({ skipUpdateEnsurance: true }))
      .then(() => dispatch(requestEnsurance()))
      .then(() => setLoaded(true));
  }, []);

  return loaded;
};

export const CustomProducts = () => {
  const voucherCode = useAppSelector((state) => state.userData.voucherCode);
  const voucherAmount = useAppSelector((state) => state.checkout.offer?.voucherTotalEuroCent);
  const voucherInfo = useAppSelector((state) => state.voucher.voucher);

  const loaded = useCustomProductsInitialLoaded();

  if (!loaded)
    return (
      <Center>
        <Loader />
      </Center>
    );

  return (
    <>
      <ScrollIntoView />
      <>
        <Container>
          <SkipCustomProductsButton />
          {voucherInfo?.valid && (
            <>
              <Title order={4}>
                Wir schenken dir{' '}
                <Box
                  component='span'
                  c='primary'>
                  {numeral(voucherAmount).format('0.00')} €
                </Box>
              </Title>
              <Title order={6}>Diese können für folgende Zusatzprodukte verwendet werden:</Title>
            </>
          )}
          <CustomProductsSelection />
          <TotalRow />
          <Space h={'lg'} />
          {GetIsDevMode() && <DebugDisplayCustomProducts />}

          <SkipCustomProductsButton />
        </Container>
      </>
    </>
  );
};
