
      import {
        Faker as FakerBase,
        de as fakerDe,
        base as fakerBase,
        en as fakerEn,
      } from 'https://esm.sh/@faker-js/faker';

      (() => {
        if (window.location.hostname !== 'localhost' && window.location.hostname !== 'stag.ovh.flarandr.de') {
          return;
        }

        const faker = new FakerBase({
          locale: [fakerDe, fakerBase, fakerEn],
        });

        faker.seed(faker.number.int());

        // 1 = Standart
        // 4 = offerRequestProcess no settings
        // 5 = offerRequestProcess
        const mode = 1;

        if (mode === 5) {
          window.ensOptions = {
            offerRequestProcess: true,
          };
        }
        if (mode === 4) {
          window.ensOptions = { refererOverride: 'alex', offerRequestProcess: true, offerRequestNoSettings: true };
        }
        if (mode === 1 || mode === 3) {
          window.ensOptions = {
            devicekey: 'select',
            saveEntryQuestion: true,
            //ensVoucherCode: 'percentage',
            //autoLoadEnsurances: true
            //fullWidth: true,
            //isFullscreen: true,

            ...(mode !== 3 ? null : { __ens_start_store: get_state_successpage() }),
          };
        } else if (mode === 2) {
          window.ensOptions = {
            refererOverride: 'alex',
            fullWidth: false,
            standaloneCompareMapping: [
              {
                compareTableId: '2',
                ensurances: [
                  'AmmerlaenderExcellentBike',
                  'AmmerlaenderExcellentPlusBike',
                  'AmmerlaenderRepSchutzbrief',
                  'AmmerlaenderExclusivBike',
                ],
              },
              {
                compareTableId: '1',
                ensurances: [
                  'AmmerlaenderExcellentBike',
                  'AmmerlaenderExcellentPlusBike',
                  'AmmerlaenderRepSchutzbrief',
                  'AmmerlaenderExclusivBike',
                ],
              },
            ],
            autoLoadEnsurances: 'compare_standalone',
            compareSelectedEns: [],
          };
        }

        const fakerSex = faker.person.sexType();
        const firstName = faker.person.firstName(fakerSex);
        const lastName = faker.person.lastName();
        const email = faker.internet.email({ firstName, lastName, provider: 'fake.unserkunde.de' });

        window.ensPreset = {
          customer: {
            forename: firstName,
            anrede: fakerSex === 'male' ? 'Herr' : 'Frau',
            birthday: faker.date.birthdate({ mode: 'age', min: 21, max: 89 }).toISOString().split('T')[0],
            plz: faker.location.zipCode({ format: '#####' }),
            city: faker.location.city(),
            email: email,
            lastname: lastName,
            street_number: '17',
            street: faker.location.street(),
            iban: faker.finance.iban({ formatted: false, countryCode: 'DE' }),
          },
          object1: {
            price: 2199,
            bikeMarke: faker.vehicle.manufacturer(),
            bikeTypeName: faker.vehicle.bicycle(),
            rahmennummer: faker.vehicle.vin(),
          },
        };
      })();
    