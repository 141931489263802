import React from 'react';
import { useAppSelector } from '@/hooks';
import { TabledPriceDisplay } from '../shared/pricing/FullPriceDisplay';

export const ZusammenfassungPriceDisplay = () => {
  const offer = useAppSelector((state) => state.checkout.offer);
  return (
    <>
      <TabledPriceDisplay
        hideCustomProducts
        customProductsGrouped></TabledPriceDisplay>
    </>
  );
};
