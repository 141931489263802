import React, { useCallback, useMemo } from 'react';

import PremiumServiceCard from '@unserkunde/enscompare-components/src/components/cards/PremiumServiceCard';
import { Loader } from '@mantine/core';
import { useAppSelector, useUserData } from '@/hooks';
import { isInspectionVoucherTest } from '@/reducer/router';
import { IsPremiumserviceProcess } from 'processes/premiumserviceProcess/PremiumserviceProcessInfo';
import { useEnsfieldsValue } from '@/features/Ensfields';

export const usePremiumserviceChecklist = (withInspectionVoucher: boolean, preview: number = 3) =>
  useMemo(
    () => ({
      labelOpen: 'Weniger anzeigen',
      labelClosed: 'Alle Vorteile anzeigen',
      preview: preview,
      items: [
        ...(!withInspectionVoucher
          ? []
          : [
              {
                uuid: '1',
                type: 'positive',
                children: 'Inspektionsgutschein im Wert von 40,-€',
              },
            ]),
        {
          uuid: '4',
          type: 'positive',
          children: 'Rückmeldung innerhalb von 24 Stunden werktags garantiert',
        },
        {
          uuid: '7',
          type: 'positive',
          children: 'Überprüfung der Kürzungen und Ablehnungen',
        },

        {
          uuid: '2',
          type: 'positive',
          children: 'Schadenmanagement & Direktabrechnung mit Werkstatt',
        },
        {
          uuid: '3',
          type: 'positive',
          children: 'Werkstattsuche im Schadenfall',
        },

        {
          uuid: '5',
          type: 'positive',
          children: 'Papierlose Bearbeitung inkl. digitalem Versicherungsordner',
        },
        {
          uuid: '6',
          type: 'positive',
          children: 'Unterstützung bei der Ersatzteilversorgung',
        },
      ],
    }),
    [withInspectionVoucher]
  );

export const usePremiumserviceAgb = (withInspectionVoucher: boolean, buttonName = null) => {
  const urlAgbInspectionVoucher = useEnsfieldsValue(
    'ens_agb_url_IV',
    'https://fahrsicherung.de/allgemeine-geschaeftsbedingungen-inspektionsgutscheine/'
  );
  const urlAgbPremiumservice = useEnsfieldsValue(
    'ens_agb_url_Premiumservice',
    'https://fahrsicherung.de/allgemeine-geschaeftsbedingungen-gegenueber-verbrauchern-b2c-premiumservice/'
  );
  const urlWiderrufPremiumservice = useEnsfieldsValue(
    'ens_widerruf_Premiumservice',
    'https://fahrsicherung.de/wp-content/uploads/2022/06/Widerruf.pdf'
  );

  if (withInspectionVoucher)
    return (
      <>
        Mit Klick auf "{buttonName || 'Premiumservice für 30,-€ jährlich zahlungspflichtig bestellen'}" bestätige ich
        die{' '}
        <a
          href={urlAgbInspectionVoucher}
          target='_blank'
          rel='noreferrer'>
          AGBs
        </a>{' '}
        der Inspektionsgutscheine, die{' '}
        <a
          target={'_blank'}
          href={urlAgbPremiumservice}
          rel='noreferrer'>
          AGBs
        </a>{' '}
        des Premiumservices, sowie die{' '}
        <a
          target='_blank'
          href={urlWiderrufPremiumservice}
          rel='noreferrer'>
          Widerrufsbelehrung
        </a>{' '}
        gelesen zu haben und akzeptiere diese. Hiermit ermächtige ich FahrSicherung, Zahlungen von meinem Konto mittels
        Lastschrift gemäß der AGB einzuziehen.
      </>
    );

  return (
    <>
      Mit Klick auf "{buttonName || 'Premiumservice für 15,-€ jährlich zahlungspflichtig bestellen'}" bestätige ich die{' '}
      <a
        target={'_blank'}
        href={urlAgbPremiumservice}
        rel='noreferrer'>
        AGBs
      </a>{' '}
      des Premiumservices, sowie die{' '}
      <a
        target='_blank'
        href={urlWiderrufPremiumservice}
        rel='noreferrer'>
        Widerrufsbelehrung
      </a>{' '}
      gelesen zu haben und akzeptiere diese. Hiermit ermächtige ich FahrSicherung, Zahlungen von meinem Konto mittels
      Lastschrift gemäß der AGB einzuziehen.
    </>
  );
};

// Wurde auf der Zusammenfassungsseite verwendet. Nur INput, kein Absenden
export const InspectionVoucherOrderComponent = () => {
  const useCta = useAppSelector((state) => state.ensfields?.inspectionvoucher_show_cta === '1');

  const [orderPremiumservice, setOrderPremiumservice] = useUserData('orderPremiumservice', null);
  const updateorderPremiumservice = useCallback((_, order) => setOrderPremiumservice(order), []);

  const agbText = usePremiumserviceAgb(false);
  const checklist = usePremiumserviceChecklist(false);

  if (!useCta && !isInspectionVoucherTest() && !IsPremiumserviceProcess()) return null;

  return (
    <>
      <br />
      <PremiumServiceCard
        title={'Nur bei FahrSicherung: Dein Premiumservice'}
        eyebrow={'Premiumservice Upgrade'}
        agbText={agbText}
        btnCallback={updateorderPremiumservice}
        value={!!orderPremiumservice && orderPremiumservice !== '0'}
        btnLabel={'Premiumservice für 15,-€ jährlich hinzufügen'}
        btnLabelDone={'Premiumservice für 15,-€ jährlich hinzugefügt'}
        checklist={checklist}
      />
    </>
  );
};

export default InspectionVoucherOrderComponent;
