import { useCallback } from 'react';
import { EnsProcessDefinitionDefault, type EnsProcessStep } from './EnsProcess';
import { useAppDispatch } from '@/hooks';
import { useEnsProcessContext } from './DefaultEnsProcessContext';
import { EnsPage, navigateTo, submitGeneral } from '@/actions/progress';

export const useNextEvent = (stepName: string) => {
  const dispatch = useAppDispatch();

  const currentProcess = useEnsProcessContext();
  const step = currentProcess.processSteps[stepName] as EnsProcessStep;

  return useCallback(() => {
    const defaultAction = (localDispatch) =>
      localDispatch((_, getState: RootStateGetter) => {
        const validationFuncs = step.validate;
        const state = getState();

        const nextStep =
          step.nextStep ||
          (() => {
            const stepKeys = Object.keys(currentProcess.processSteps);
            let currentStepIndex = stepKeys.indexOf(stepName);

            while (currentStepIndex < stepKeys.length - 1) {
              currentStepIndex += 1;
              const pointedStep = currentProcess.processSteps[stepKeys[currentStepIndex]] as EnsProcessStep;
              if (!pointedStep.stepIsVisible || pointedStep.stepIsVisible({ state })) return stepKeys[currentStepIndex];
            }

            throw new Error('No next step found');
          })();

        return dispatch(submitGeneral(validationFuncs, nextStep, null));
      });

    if (step.nextStepAction) step.nextStepAction(dispatch, defaultAction);
    else defaultAction(dispatch);
  }, [step, stepName, currentProcess]);
};

export const usePrevEvent = (stepName: string) => {
  const currentProcess = useEnsProcessContext() as EnsProcessDefinitionDefault;
  const dispatch = useAppDispatch();
  const step = currentProcess.processSteps[stepName] as EnsProcessStep;

  return useCallback(() => {
    const defaultAction = (localDispatch) =>
      localDispatch((_, getState: RootStateGetter) => {
        const state = getState();
        const stepKeys = Object.keys(currentProcess.processSteps);
        let currentIndex = stepKeys.indexOf(stepName);

        while (currentIndex > 0) {
          currentIndex -= 1;
          const pointedStepName = stepKeys[currentIndex];
          const pointedStep = currentProcess.processSteps[pointedStepName] as EnsProcessStep;

          if (!pointedStep.stepIsVisible || pointedStep.stepIsVisible({ state })) {
            dispatch(navigateTo(pointedStepName as EnsPage));
            return;
          }
        }

        throw new Error('No previous step found');
      });

    if (step.prevStepAction) step.prevStepAction(dispatch, defaultAction);
    else defaultAction(dispatch);
  }, [stepName, currentProcess]);
};
